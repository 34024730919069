<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-6 offset-md-4">
        <h4 class="font-weight-bold">{{ 'settings.heading' | translate }}</h4>
      </div>
    </div>
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div v-else class="row mt-4 mb-3">
      <div class="col-md-3 offset-md-1">
        <img
          v-if="profile"
          @error="imageNotFound"
          :src="avatar"
          alt="profile-image"
          class="img-fluid rounded-circle"
          width="100%"
          height="auto"
        />
        <input
          type="file"
          ref="photoUpload"
          @change="resizeAndSetImage"
          class="hidden"
          accept="image/gif, image/jpeg, image/png"
        />
        <secondary-button class="btn-block mb-2" @click="uploadPhoto"
          >{{ 'settings.uploadPhoto' | translate }}
        </secondary-button>
      </div>
      <div class="col-md-7 mb-18">
        <form class="border-bottom pb-3" @submit.prevent="() => {}">
          <div class="form-group" id="firstName">
            <label for="name">{{ 'form.firstName' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('form.firstName')"
              v-model="user.firstName"
              required
            />
          </div>
          <div class="form-group" id="lastName">
            <label for="name">{{ 'form.lastName' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('form.lastName')"
              v-model="user.lastName"
              required
            />
          </div>
          <div class="form-group" id="email">
            <label for="email">{{ 'form.email' | translate }}</label>
            <h6>
              <span class="text-muted">{{ user.email }}</span>
              <small>
                <button @click="changeEmail" class="btn btn-secondary ml-2">{{ 'general.change' | translate }}</button>
              </small>
            </h6>
          </div>
          <div class="form-group" id="workEmail" v-if="isTheOnboardeeOnTheJob && profile.workEmail">
            <label for="email">{{ 'onboardee.create.workEmail' | translate }}</label>
            <h6>
              <span class="text-muted">{{ profile.workEmail }}</span>
            </h6>
          </div>
          <div class="form-group" id="password">
            <label for="password">{{ 'form.password' | translate }}</label>
            <p>
              <router-link to="/change-password">{{ 'settings.changeYourPassword' | translate }}</router-link>
            </p>
          </div>
          <div class="form-group" id="languange" v-if="user && availableLanguages && availableLanguages.length > 1">
            <label>{{ 'form.language' | translate }}</label>
            <select name="language" class="form-control" v-model="user.language">
              <option v-for="language in availableLanguages" :key="language.id" :value="language.localeCode">
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="form-group" id="twoFactorAuth" v-if="profile && profile.allTypes && profile.allTypes.length">
            <label>{{ 'settings.twoFactorAuthentication' | translate }}</label>
            <p>
              <button v-if="mfaData.twoFactorEnabled" @click="resetTwoFactorAuth" class="btn btn-secondary">
                {{ 'general.reset' | translate }}
              </button>
              <router-link to="/profile/two-factor-setup" v-if="!profile.userTypes || !profile.userTypes.length"
                >{{ 'settings.enable' | translate }}
              </router-link>
            </p>
          </div>
          <br />
          <primary-button class="mt-1 m-auto" type="button" :loading="updating" @click="save()"
            >{{ 'general.save' | translate }}
          </primary-button>
        </form>
        <div class="pt-3 pb-3 border-bottom" id="exportUserData" v-if="!disableGdprFunctions">
          <p class="font-weight-bold">{{ 'settings.exportUserDataInfo' | translate }}</p>
          <default-button class="mt-1 m-auto" type="button" :loading="exportUserDataLoading" @click="exportUserData()"
            >{{ 'settings.exportUserDataLink' | translate }}
          </default-button>
        </div>
        <div class="pt-3 pb-3" id="deleteAccount" v-if="!disableGdprFunctions">
          <p class="font-weight-bold">{{ 'settings.deleteAccountInfoText' | translate }}</p>
          <router-link class="btn btn-danger mb-2" to="/profile/delete-account"
            >{{ 'settings.deleteAccount' | translate }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import omit from 'lodash/omit';
import { mapGetters } from 'vuex';

import resizeImageMixin from '@app/mixins/resize-image';
import fixImageOrientationMixin from '@app/mixins/fix-image-orientation';

import femaleAvatar from '@shared/assets/img/avatar-w.png';
import maleAvatar from '@shared/assets/img/avatar-m.png';
import { updateLanguage } from '@shared/translations';
import { deleteUserMfaConfiguration, getUserMfaConfigurations } from '@app/http/authentication';

export default {
  mixins: [fixImageOrientationMixin, resizeImageMixin],
  created() {
    Promise.all([this.getProfile(), this.getTenantData(), getUserMfaConfigurations(this.user.id)]).then((responses) => {
      this.mfaData = responses[2].data;
      this.loading = false;
    });
  },
  data() {
    return {
      currentPassword: null,
      photo: null,
      updating: false,
      exportUserDataLoading: false,
      profile: null,
      twoFactorAuthType: null,
      showConfirmEmailModal: false,
      showPasswordModal: false,
      isTheOnboardeeOnTheJob: false,
      disableGdprFunctions: true,
      loading: true,
      mfaData: {
        availableProviders: [],
        twoFactorEnabled: false
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('settings', ['availableLanguages', 'company']),
    avatar() {
      const avatar = this.profile.gender === 2 ? femaleAvatar : maleAvatar;
      if (this.photo) {
        return this.photo;
      } else if (this.profile?.imageURL?.length) {
        return this.profile.imageURL;
      }
      return avatar;
    }
  },
  methods: {
    getProfile() {
      const profileCall = this.$http.get('/profile');
      const onboardeeInformationCall = this.$http.get(
        `/v2/onboardee-information?utcMinutesOffset=${new Date().getTimezoneOffset() * -1}`
      );

      return Promise.all([profileCall, onboardeeInformationCall]).then(
        ([profileResponse, onboardeeInformationResponse]) => {
          this.profile = profileResponse.data;
          if (this.profile.userTypes.length) {
            this.twoFactorAuthType = this.profile.userTypes[0].id;
          }
          this.isTheOnboardeeOnTheJob = onboardeeInformationResponse.data.calendarDays < 1;
        }
      );
    },
    getTenantData() {
      return new Promise((resolve) => {
        this.$http.get(`/v2/companies/tenantdata`).then((tenantDataResponse) => {
          this.disableGdprFunctions = tenantDataResponse.data.disableGdprFunctions;
          return resolve();
        });
      });
    },
    imageNotFound(event) {
      event.target.src = this.user.gender === 2 ? femaleAvatar : maleAvatar;
    },
    uploadPhoto() {
      this.$refs.photoUpload.click();
    },
    save() {
      this.$prompt(this.$t('form.enterCurrentPasswordText'), {
        confirmButtonText: this.$t('general.save'),
        cancelButtonText: this.$t('general.cancel'),
        inputPlaceholder: this.$t('form.currentPasswordPlaceholder'),
        inputType: 'password'
      }).then(({ value }) => {
        this.updating = true;
        this.$http.put('/profile', { ...omit(this.user, 'email'), password: value, UserImage: this.photo }).then(
          (response) => {
            const languageId = this.company.languages.find((language) => language.localeCode === this.user.language)
              .languageId;

            let user = JSON.parse(window.localStorage.getItem('user'));
            user.language = this.user.language;
            user.languageId = languageId;
            window.localStorage.setItem('user', JSON.stringify(user));
            window.localStorage.setItem('defaultLanguage', user.language);

            updateLanguage(this.user.language);

            this.$notify({
              type: 'success',
              message: this.$t('notifications.profileUpdated'),
              position: 'bottom-right'
            });
            this.updating = false;
          },
          (err) => {
            if (err.data && err.data.error) {
              this.$notify({
                type: 'error',
                message: err.data.error,
                position: 'bottom-right'
              });
            } else {
              this.$notify({
                type: 'error',
                message: this.$t('notifications.error'),
                position: 'bottom-right'
              });
            }
            this.updating = false;
          }
        );
      });
    },
    async resizeAndSetImage(event) {
      let file = event.target.files[0];

      const size = file.size;
      const sizeInKb = size / 1024; // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
      const sizeInMb = sizeInKb / 1024; // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
      if (sizeInMb > 20) {
        return this.$notify({
          type: 'error',
          message: this.$t('notifications.wrongPictureFormat'),
          position: 'bottom-right'
        });
      }
      this.fixImageOrientation(file, async (fixedFile) => {
        file = fixedFile;

        const config = {
          file,
          size: 500
        };

        this.photo = await this.resizeImage(config, true);
        this.$set(this.user, 'imageURL', this.photo);
        Vue.nextTick();
      });
    },
    changeEmail() {
      const user = this.user;
      const changeEmail = this.$t('settings.changeYourEmail');

      this.$prompt(this.$t('form.email'), {
        confirmButtonText: this.$t('general.save'),
        cancelButtonText: this.$t('general.cancel'),
        inputPlaceholder: this.$t('form.email'),
        inputValidator(input) {
          return input === user.email ? changeEmail : true;
        },
        inputType: 'email',
        inputValue: this.user.email
      }).then(({ value }) => {
        this.$confirm(this.$t('settings.changeEmailConfirmation', { email: value }), 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.originalEmail = this.user.email;
          this.user.email = value;
          this.confirmEmailChange();
        });
      });
    },
    confirmEmailChange() {
      const { email } = this.user;
      this.$http.post('/profile/change-email', { email }).then(
        (response) => {
          this.$notify({
            type: 'success',
            message: this.$t('notifications.emailChange', { email: email }),
            position: 'bottom-right'
          });
        },
        (err) => {
          if (err.data && err.data.error) {
            this.$notify({
              type: 'error',
              message: err.data.error,
              position: 'bottom-right'
            });
          } else {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.error'),
              position: 'bottom-right'
            });
          }
          this.user.email = this.originalEmail;
        }
      );
    },
    resetTwoFactorAuth() {
      const h = this.$createElement;
      this.$msgbox({
        title: this.$t('general.reset') + ' ' + this.$t('settings.twoFactorAuthentication'),
        message: h('p', null, [h('p', this.$t('bulkwizard.common.confirmationquestion'))]),
        showCancelButton: true,
        confirmButtonText: this.$t('general.reset'),
        cancelButtonText: 'Cancel',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = this.$t('general.loading') + '...';
            setTimeout(() => {
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
              done();
            }, 600);
          } else {
            done();
          }
        }
      })
        .then((action) => {
          if (action === 'confirm') {
            deleteUserMfaConfiguration(this.user.id, this.mfaData.availableProviders[0].id)
              .then(() => {
                this.mfaData = {
                  availableProviders: [],
                  twoFactorEnabled: false
                };
                this.getProfile();
                this.$notify({
                  type: 'success',
                  message: this.$t('settings.twoFactorAuthenticationDisabled'),
                  position: 'bottom-right'
                });
              })
              .catch((err) => {
                if (err && err.error) {
                  this.$notify({
                    type: 'error',
                    message: err.error,
                    position: 'bottom-right'
                  });
                }
              });
          }
        })
        .catch((err) => {});
    },
    exportUserData() {
      this.exportUserDataLoading = true;
      this.$http.post('/profile/data-export').then(
        (response) => {
          this.$notify({
            type: 'success',
            message: this.$t('notifications.exportUserDataSuccess'),
            position: 'bottom-right'
          });
          this.exportUserDataLoading = false;
        },
        (err) => {
          if (err.data && err.data.error) {
            this.$notify({
              type: 'error',
              message: err.data.error,
              position: 'bottom-right'
            });
          } else {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.error'),
              position: 'bottom-right'
            });
          }
          this.exportUserDataLoading = false;
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';

label {
  font-weight: bold;
  text-transform: capitalize;
}

input.hidden {
  visibility: hidden;
  height: 0;
}

h4 {
  font-size: var(--title-size);
}
</style>
